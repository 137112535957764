<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="orders">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <b-col class="mb-1" cols="12" md="12">
              <h1>Order Warranties</h1>
            </b-col>
            <!-- Search -->
            <b-col
                cols="12"
                md="3"
            >
              <b-form-group
                  class="mb-2 mt-25"
                  label="Count"
              >
                <v-select
                    v-model="perPage"
                    :clearable="false"
                    :options="perPageOptions"
                    class="per-page-selector w-100 d-inline-block "
                />

              </b-form-group>

            </b-col>
            <b-col
                class="mt-25"
                cols="12"
                md="3"
            >
              <b-form-group label="Search">
                <Debouncer @setValue="setQuery"></Debouncer>
              </b-form-group>

            </b-col>


          </b-row>


        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="orders"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            sort-by.sync
            striped
        >

          <!-- Column: Status -->


          <!-- Column: sumPrice -->
          <template #cell(finalPrice)="data">
         <span>
               {{ makePrettyPrice(data.item.finalPrice) + ' ' + getCurrency(data.item.currencyName) }}
            </span>
          </template>
          <template #cell(orderStatus)="data">


            <b-badge
                v-if="data.item.orderStatus==='Frozen'"
                pill
                variant="info"
            >
              Frozen
            </b-badge>

          </template>
          <!-- Column: createDate -->
          <template #cell(createdAt)="data">
            <span>


             {{
                new Date(data.item.createdAt).toLocaleDateString('en-US') + ' - ' +
                new Date(data.item.createdAt).toLocaleTimeString('en-US')
              }}
            </span>
          </template>
          <!--          <template #cell(expireAt)="data">-->
          <!--            <span v-if="data.item.expireAt">-->


          <!--             {{-->
          <!--                new Date(data.item.expireAt).toLocaleDateString('en-US') + ' - ' +-->
          <!--                new Date(data.item.expireAt).toLocaleTimeString('en-US')-->
          <!--              }}-->
          <!--            </span>-->
          <!--          </template>-->
          <!-- Column: isDelivered -->

          <!-- Column: Actions -->
          <template #cell(timeLeft)="data">

            <span>{{ data.item.orderWarranties.timeLeft }} days</span>

          </template>

          <template #cell(detail)="data">


            <b-link :to="{ name: 'apps-orders-warranties-warrantyInfo', params: { id: data.item.orderCode } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCounts"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        title="Delete Order"
        ok-title="Delete"
        @ok="deleteOrder"
    >
      <span>Are You Sure You Want To Delete This Order ?</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BBadge, BCard, BCol, BFormGroup, BLink, BModal, BOverlay, BPagination, BRow, BTable, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { OrderChangeStatusRequest, OrderDeleteRequest } from '@/libs/Api/Order'
import Helper from '@/libs/Helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Debouncer from '@/views/components/debouncer.vue'
import { GetOrderWarranties } from '@/libs/Api/orderWarrnties'

export default {
  name: 'Orders Warranties',
  title: 'Orders Warranties',
  data() {
    return {
      orders: null,
      deleteItem: null,
      currentPage: 1,
      totalCounts: null,
      perPage: 10,
      orderStatuses: [
        {
          key: 'Waiting',
          label: 'Waiting For payment',
          variant: 'secondary'
        },
        {
          key: 'Payed',
          label: 'Waiting For Boosters To Assign',
          variant: 'light-success'
        },
        {
          key: 'Doing',
          label: 'Booster Assigned',
          variant: 'success'
        },
        {
          key: 'Done',
          label: 'Booster Completed Order',
          variant: 'success'
        },
        {
          key: 'Cancel',
          label: 'Waiting For Reassign',
          variant: 'danger'
        },
      ],
      orderTypes: [
        {
          name: 'Default',
          value: 'Default'
        },
        {
          name: 'Express',
          value: 'Express'
        },
        {
          name: 'Force',
          value: 'ForceMajor'
        },
      ],
      overlay: false,
      perPageOptions: [10, 20, 30, 40, 50],
      selectedOrder: null,
      myTableColumns: [
        {
          key: 'orderCode',
          label: 'order code',
          sortable: true
        },
        {
          key: 'finalPrice',
          label: 'price',
          sortable: true
        },
        {
          key: 'boosterUserName',
          label: 'booster',
          sortable: true
        },
        {
          key: 'orderStatus',
          label: 'current status',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'create date',
          sortable: true
        },
        // {
        //   key: 'expireAt',
        //   label: 'expire date',
        //   sortable: true
        // },

        {
          key: 'timeLeft',
          label: 'timeLeft'
        },
        {
          key: 'detail',
          label: 'detail'
        },
      ],
      searchQuery: '',
      selectedStatus: null,
      selectedType: null
    }
  },
  async created() {
    await this.getOrders()
  },
  methods: {
    setQuery(data) {
      this.searchQuery = data
      this.getOrders()
    },
    getOrderStatus(data) {
      let idx = this.orderStatuses.findIndex(e => e.key === data)
      return this.orderStatuses[idx]
    },
    async getOrders() {
      let _this = this
      _this.overlay = true
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        search: _this.searchQuery,
      }
      let getOrderWarranties = new GetOrderWarranties(_this)
      getOrderWarranties.setParams(data)
      await getOrderWarranties.fetch(function (content) {
        _this.overlay = false
        _this.orders = content.data.data
        _this.totalCounts = content.data.totalCount
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    async deleteOrder(param) {
      let _this = this
      _this.overlay = true

      let orderDeleteRequest = new OrderDeleteRequest(_this)
      orderDeleteRequest.setParams({ orderId: _this.selectedOrder.id })
      await orderDeleteRequest.fetch(function (content) {
        _this.overlay = false
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.getOrders()
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: content.message,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    getTime(time) {
      return Helper.tellTheTime(time)
    },
    async changeOrderStatus(orderId, status) {
      let _this = this
      _this.overlay = true
      let data = {
        orderId: orderId,
        status: status,
      }

      let orderChangeStatusRequest = new OrderChangeStatusRequest(_this)
      orderChangeStatusRequest.setId(data)
      await orderChangeStatusRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات اعمال شد.`,
          },
        })
        _this.getOrders(_this.perPage, _this.currentPage, _this.searchQuery, _this.searchPurchasedByAdmin)
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    getCurrency(data) {
      return Helper.getCurrencySymbol(data)
    },
    setSelectedOrder(data) {
      this.selectedOrder = JSON.parse(JSON.stringify(data))
    }
  },
  watch: {
    perPage: function (nv, ov) {
      this.getOrders()
    },
    currentPage: function (nv, ov) {
      this.getOrders()
    },
    selectedStatus: function () {
      this.getOrders()
    },
    selectedType: function () {
      this.getOrders()
    },
  },
  components: {
    Debouncer,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
